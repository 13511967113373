import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import ProjectDetails from "../../images/projects/Alsea_Avilion-Gardens_Altiplano.yml"

const AvilionGardens = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query($floorPlan: String = "/images/projects/avilion/04 FLOOR PLANS/") {
      logo: file(relativePath: { eq: "avilion-gardens-logo.png" }) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: { eq: "projects/avilion/AVILION SDP.png" }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "HouseUnits", num: "33" },
    { metric: "TypesofUnits", num: "2" },
    { metric: "TypicalLotArea", num: "77 - 133" },
    { metric: "TypicalFloorArea", num: "126 - 154" },
  ])
  const props: LocationTemplateProps = {
    title: "Avilion Gardens",
    logo,
    location: "PARANAQUE",
    metrics,
    carouselImgs: [
      require("../../images/projects/avilion/1-avilion-carousel.jpg"),
      require("../../images/projects/avilion/2-avilion-carousel.jpg"),
      require("../../images/projects/avilion/3-avilion-carousel.jpg"),
      require("../../images/projects/avilion/4-avilion-carousel.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Live and breathe tranquility. Avilion Gardens employs only the most progressive life-saving systems of security, including high-standard CCTV’s in each unit.  Neighbors with Altiplano - a 2 building condominium, Avilion has a total 33 units. Surrounded by luscious greens and fresh air, Altiplano is a fully equipped community with its own clubhouse, chapel, gym, swimming pool, and playground.",
    },
    amenities: {
      description:
        "Enjoy the various shared amenities within the community, fully equipped with a swimming pool & playground, and a clubhouse that houses a prayer room, fitness gym, and function room - all within reach and easy access.",
      items: [
        {
          img: require("../../images/projects/avilion/amenities/01 AVILION CLUBHOUSE.jpg"),
          name: "Clubhouse Exterior",
          description:
            "The Avilion Gardens Clubhouse is ready for anyone's use with its function room, chapel, and gym. The clubhouse is surrounded by other outdoor amenities such as the swimming pool and the playground.",
        },
        {
          name: "Playground",
          description:
            "Discover endless fun with your family at the playground.",
          img: require("../../images/projects/avilion/amenities/02 AVILION PLAYGROUND.jpg"),
        },
        {
          name: "Swimming Pool",
          description:
            "Enjoy the sun and have a relaxing time by the pool area.",
          img: require("../../images/projects/avilion/amenities/03 AVILION SWIMMING POOL.jpg"),
        },
        {
          name: "Function Room",
          description: "Make memories and gather with friends and family for special occasions in the private function room.",
          img: require("../../images/projects/avilion/amenities/04 AVILION FUNCTION ROOM.jpg"),
        },
        {
          name: "Chapel",
          description:
            "Find inner peace in the prayer room wherein you can set your intentions in a safe and sacred space.",
          img: require("../../images/projects/avilion/amenities/05 AVILION CHAPEL.jpg"),
        },
        {
          name: "Gym",
          description:
            "Gain physical wellness and build up your strength in the gym fully equipped with the essentials.",
          img: require("../../images/projects/avilion/amenities/06 AVILION GYM.jpg"),
        },
      ],
    },
    floorPlans: {
      description:
        "Avilion Gardens offers two (2) types of units - 2-storey unit (126.01 sq.m) and 3-storey unit (154.28 sq.m). Focusing on environment infused living through its use of open green spaces, all units are equipped with its own private garden and balconies. ** All Floor Plans below are for suggested layout only. ",
      items: [
        [
          {
            name: "2-Storey Unit",
            fpImg: fpImg("AVILION 2-STOREY FP"),
            pImg: fpImg("AVILION 2-STOREY PERSP"),
          },
          {
            name: "3-Storey Unit",
            fpImg: fpImg("AVILION 3-STOREY FP"),
            pImg: fpImg("AVILION 3-STOREY PERSP"),
          },
        ],
      ],
    },
    modelUnits: [
      require("../../images/projects/avilion/05 MODEL UNIT/01 AVILION GARDENS LIVING_DINING.jpg"),
      require("../../images/projects/avilion/05 MODEL UNIT/02 AVILION GARDENS LIVING.jpg"),
      require("../../images/projects/avilion/05 MODEL UNIT/03 AVILION GARDENS KITCHEN.jpg"),
      require("../../images/projects/avilion/05 MODEL UNIT/04 AVILION GARDENS MASTER BEDROOM.jpg"),
      require("../../images/projects/avilion/05 MODEL UNIT/05 AVILION GARDENS FAMILY ROOM.jpg"),
    ],
    projDetails: {
      ...ProjectDetails,
      name: "Avilion Gardens",
      description: `Strategically located in the heart of Parañaque is a secure community  designed in collaboration with Master Planner, Palafox Associates. Avilion Gardens features environment infused living through its use of open green spaces within a secured neighborhood.

      With a limited number of units, the Avilion development showcases generous space for everyone. Each unit is equipped with its own private pocket garden designed for sustainable living environment.

      Two (2) types of units - 2-storey and 3-storey, are available to cater to everyone's needs.`,
    },
  }
  return <Template {...props} />
}

export default AvilionGardens
